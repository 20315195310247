import React from 'react'

const Linkedin = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="w-full fill-current"
		viewBox="0 0 32 32"
	>
		<path
			d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm5.6 6.4H10.4a4 4 0 0 0-4 4v11.2a4 4 0 0 0 4 4h11.2a4 4 0 0 0 4-4V10.4a4 4 0 0 0-4-4zm-9.245 7.371v8.612H9.492v-8.612h2.863zm7.026-.202c1.883 0 3.296 1.231 3.296 3.876v4.938h-2.863v-4.608c0-1.159-.413-1.947-1.45-1.947-.79 0-1.262.535-1.47 1.047-.075.186-.094.438-.094.699v4.81h-2.862v-.105c.003-.52.014-2.897.016-5.017v-1.57a76.435 76.435 0 0 0-.016-1.921H16.8v1.22c.383-.587 1.062-1.422 2.581-1.422zm-8.44-3.95c.98 0 1.583.643 1.601 1.49 0 .824-.62 1.487-1.62 1.487h-.018c-.96 0-1.581-.663-1.581-1.488 0-.846.64-1.489 1.618-1.489z"
			fillRule="evenodd"
		/>
	</svg>
)

export default Linkedin
