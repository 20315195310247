import React from 'react'

const Pinterest = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="w-full fill-current"
		viewBox="0 0 34 34"
	>
		<path
			d="M17,0C7.611,0,0,7.61,0,17c0,9.391,7.611,17,17,17c9.389,0,17-7.609,17-17C34,7.61,26.389,0,17,0z M28.211,17.595
				c-1.795,3.79-6.324,5.89-10.521,4.636c0.346,2.484-0.535,5.512-3.253,6.435c-1.299,0.44-2.715,0.242-3.945-0.292
				c-1.342-0.58-0.622-2.862-0.734-4.014c0.818,0.172,1.604,1.002,2.489,0.607c0.765-0.338,0.784-1.979,0.812-2.662
				c0.096-2.295-0.076-4.619-0.076-6.917c0-0.77,0-1.539,0-2.308c0-0.315,0-0.632,0-0.948c0-0.391,0.021-0.332,0.381-0.395
				c0.964-0.165,1.978-0.113,2.947-0.038c0.276,0.022,0.551,0.049,0.827,0.082c0.35,0.044,0.309-0.035,0.309,0.385
				c0,0.687,0,1.373,0,2.058c0,1.373,0,2.744,0,4.115c0,0.525,0.433,0.509,0.92,0.622c0.287,0.066,0.578,0.117,0.871,0.146
				c0.527,0.053,1.062,0.037,1.582-0.056c0.893-0.157,1.734-0.55,2.399-1.141c1.271-1.128,1.571-2.852,1.112-4.409
				c-1.107-3.764-6.064-5.286-9.724-4.403c-3.93,0.948-7.069,5.203-4.106,8.637c-0.886,0.757-1.649,1.656-2.685,2.24
				C7.563,20.12,7.3,20.241,7.028,20.336c-0.151-0.156-0.293-0.325-0.424-0.499c-0.271-0.356-0.5-0.744-0.689-1.146
				c-0.854-1.806-0.99-3.915-0.633-5.851c0.58-3.148,2.662-5.484,5.725-6.699c5.102-2.019,12.416-1.192,16.021,3.105
				C28.949,11.543,29.488,14.896,28.211,17.595z"
		/>
	</svg>
)

export default Pinterest
