import React from 'react'

const Youtube = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="w-full fill-current"
		viewBox="0 0 32 32"
	>
		<path
			d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm5.612 9.6H10.388A3.99 3.99 0 0 0 6.4 13.592v5.616a3.99 3.99 0 0 0 3.988 3.992h11.224a3.99 3.99 0 0 0 3.988-3.992v-5.616A3.99 3.99 0 0 0 21.612 9.6zm-7.942 4.031 5.25 2.664a.211.211 0 0 1-.004.378l-5.25 2.507a.21.21 0 0 1-.302-.19v-5.17a.21.21 0 0 1 .306-.189z"
			fillRule="evenodd"
		/>
	</svg>
)

export default Youtube
