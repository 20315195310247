import React from 'react'

const Behance = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="w-full fill-current"
		viewBox="0 0 32 32"
	>
		<path
			d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm5.6 6.4H10.4l-.2.005A4 4 0 0 0 6.4 10.4v11.2l.005.2a4 4 0 0 0 3.995 3.8h11.2l.2-.005a4 4 0 0 0 3.8-3.995V10.4l-.005-.2A4 4 0 0 0 21.6 6.4zm-8.475 4.695c1.194.017 2.042.364 2.542 1.045.3.417.448.916.448 1.497 0 .602-.15 1.08-.454 1.445-.168.205-.417.39-.747.558.5.184.878.47 1.131.868.255.394.382.875.382 1.436.001.583-.142 1.106-.434 1.567a2.733 2.733 0 0 1-.695.771c-.313.24-.683.405-1.108.493a6.777 6.777 0 0 1-1.384.133h-4.42v-9.813zm7 2.292c.611 0 1.16.109 1.653.327.49.221.894.567 1.212 1.042.29.418.474.902.561 1.455.05.319.072.785.063 1.393h-5.12l.014.188c.062.607.3 1.038.722 1.296.279.177.617.263 1.013.263.419 0 .756-.105 1.02-.32.142-.116.267-.278.377-.483h1.876l-.027.157c-.081.366-.299.736-.655 1.113-.627.684-1.51 1.027-2.642 1.027-.937 0-1.761-.29-2.477-.866-.713-.576-1.073-1.515-1.073-2.815 0-1.219.322-2.153.968-2.803.648-.65 1.484-.974 2.514-.974zm-7.37 3.2H10.65v2.616h2.073l.205-.004c.329-.016.591-.071.79-.168.428-.214.644-.622.644-1.226 0-.51-.208-.862-.625-1.053-.233-.107-.56-.16-.98-.165zm7.373-1.687c-.473 0-.839.136-1.099.403s-.422.63-.49 1.088h3.168l-.02-.177c-.059-.401-.215-.712-.472-.933-.291-.254-.654-.381-1.087-.381zm-7.653-2.102H10.65v2.165h2.076l.207-.007c.333-.023.61-.102.831-.237.265-.161.397-.449.397-.863 0-.456-.174-.76-.526-.905-.303-.1-.69-.153-1.159-.153zm9.612-1.297v.981h-3.951v-.981h3.951z"
			fillRule="evenodd"
		/>
	</svg>
)

export default Behance
